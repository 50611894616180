import styled, { css } from "styled-components";

const quotesStyle = css`
  position: absolute;
  color: var(--eve-blue);
  top: 20px;
  left: 20px;
  ${(props) =>
    props.bottom &&
    `top: unset;
      left: unset;
      right: 20px;
      bottom: 20px;`}
`;

export const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: 100%;
  max-width: 334px;
  position: relative;
`;

export const CardHead = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: flex-start;
  max-height: 105px;
`;

export const CardImg = styled.div`
  width: 100%;
  max-width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #fff center center no-repeat;
  background-size: cover;
  ${(props) => props.img && `background-image: url(${props.img});`}
  box-shadow: 0px 101px 80px rgba(0, 0, 0, 0.07),
    0px 42.1954px 33.4221px rgba(0, 0, 0, 0.0543167),
    0px 22.5597px 17.869px rgba(0, 0, 0, 0.0514083),
    0px 12.6468px 10.0172px rgba(0, 0, 0, 0.0477214),
    0px 6.7166px 5.32008px rgba(0, 0, 0, 0.0399575),
    0px 2.79493px 2.21381px rgba(0, 0, 0, 0.0253409);
  margin-right: 20px;
`;

export const CardHeader = styled.div`
  h6 {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.055em;
    color: #000000;
    margin-bottom: 6px;
  }

  p {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.055em;
    color: rgba(0, 0, 0, 0.67);
    margin-bottom: 0;
  }
`;

export const CardBody = styled.div`
  background: #f1f1f1;
  border-radius: 15px;
  padding: 55px 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 235px;
`;

export const Quote = styled.span`
  ${quotesStyle}
`;

export const CardText = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.055em;
  color: #000000;
  width: 100%;
`;

import React from "react";

import { SECTIONS } from "../../../config/constants";

import BtnLink from "../../UICs/BtnLink/BtnLink.uic";

import "./EveConsulting.styles.scss";

const EveConsulting = () => {
  return (
    <div
      className="l-eveconsulting"
      id={SECTIONS.eveconsulting}
      data-scrollto-delta="75"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="eveconsulting">
              <h1 className="home-title">
                Improving Your Digital Transformation Journey
              </h1>
              <p className="home-text">
                <strong>EVEREST</strong> est un cabinet de conseil qui
                accompagne la réussite de projets de transformation. Nous
                assistons nos clients dans l’identification de nouveaux relais
                de croissance et dans leurs mutations digitales
              </p>
              <BtnLink
                isUppercase
                radius={34}
                height={58}
                className="scroll-link"
                to={`#${SECTIONS.contacts}`}
              >
                Nous contacter
              </BtnLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EveConsulting;

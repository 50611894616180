import React from "react";

import "./Missions.styles.scss";

const Missions = () => {
  return (
    <div className="l-missions">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="mission-title">Nos missions</h2>

            <div className="missions">
              <div className="mission">
                <div className="mission-number">01</div>
                <div className="mission-title">L'objectif Everest</div>
                <div className="mission-text">
                  Devenir le leader international du marché de la performance
                  des entreprises.
                </div>
              </div>
              <div className="mission">
                <div className="mission-number">02</div>
                <div className="mission-title">L’engagement Everest</div>
                <div className="mission-text">
                  Améliorer la performance des entreprises en qualité de
                  partenaire stratégique, en partageant notre expertise.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Missions;

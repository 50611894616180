import chessKing from "@iconify/icons-la/chess-king";
import codeIcon from "@iconify/icons-heroicons-solid/code";
import chartComboStacked from "@iconify/icons-carbon/chart-combo-stacked";
import contentLifecycleManagement from "@iconify/icons-eos-icons/content-lifecycle-management";

export const ListTeam = [
  {
    img: require("../assets/images/team/team1.png").default,
    name: "Anthony",
    role: "Partner STRATEGY & RISK CONSULTING",
    description:
      "Apporter aux entreprises des réponses pertinentes à leurs objectifs de transformation",
  },
  {
    img: require("../assets/images/team/team2.png").default,
    name: "Andrea",
    role: "Senior Manager BUSINESS TRANSFORMATION - DATA & ANALYTICS",
    description:
      "Je suis passionnée par l’IA, le décryptage des données, l’interprétation des résultats afin de résoudre des problèmes importants pour améliorer la qualité de vie des populations",
  },
  {
    img: require("../assets/images/team/team3.png").default,
    name: "Yann",
    role: "Senior Manager DATA & ANALYTICS - RISK CONSULTING",
    description:
      "J’accorde une grande importance à la science des données  et aux chiffres. Le traitement des données pour en tirer des informations à valeur ajoutée et orienter les décisions des clients est un défi qui me passionne",
  },
  {
    img: require("../assets/images/team/team4.png").default,
    name: "Nicolas",
    role: "Manager Business Transformation",
    description:
      "Mon leitmotiv est d’accompagner les structures dans la mise en place d’outils automatisés de pilotage et de maitrise des risques (opérationnels et financiers)",
  },
  {
    img: require("../assets/images/team/team5.png").default,
    name: "Alexis",
    role: "SUPERVISOR PROCESS DIGITALIZATION",
    description:
      "Je suis motivé par l’optimisation des processus internes et l’amélioration de l’expérience client",
  },
];

export const SliderData = [
  {
    icon: chessKing,
    title: "Strategy and operation",
    description:
      "Face aux enjeux de croissance et de compétitivité, nous vous accompagnons de la définition de plan stratégique à la mise en œuvre effective de vos projets. Nous garantissons un alignement fort entre la stratégie, l’amélioration continue des processus et le capital humain. Nous contribuons ainsi à l’atteinte de vos objectifs en détectant des stratégies gagnantes de captation de valeur et d’efficience opérationnelle pour votre organisation.",
    list: [
      "Transformation digital",
      "Stratégie digitale",
      "Structuration et conduite de projet de transformation",
      "Accélération de processus",
      "Marketing et relation client",
      "Opération",
      "Digitalisation de la fonction finance",
    ],
  },
  {
    icon: codeIcon,
    title: "Web and mobile development",
    description:
      "Dans un environnement en forte mutation où le numérique révolutionne les services et les « business model », nous vous accompagnons dans la réinvention de vos processus opérationnels à travers des solutions technologiques à la pointe de l’innovation. A travers notre démarche participative empreinte d’agilité, nous accompagnons la conduite de vos projets de transformation et accélérons leurs succès par une conduite du changement forte.",
    list: [
      "Développement web",
      "Développement back end",
      "UI/UX et design de logo",
      "Accélération de processus",
      "iOS et Android développement",
      "Conception d’infrastructure robuste",
    ],
  },
  {
    icon: chartComboStacked,
    title: "Advance data analysis",
    description:
      "La volumétrie de données générées par les entreprises est de plus en plus importante et par conséquent, de plus en plus difficile à appréhender.  Nous vous apportons notre analyse et nos idées afin de tirer le meilleur parti de vos données et répondre aux enjeux de croissance, de transformation et de gestion des risques.",
    list: [
      "Analyse de donnée",
      "Automatisation du reporting",
      "Analyse dynamique en temps réel",
      "Amélioration de la relation client",
      "Objet connecté",
    ],
  },
  {
    icon: contentLifecycleManagement,
    title: "Risk management",
    description:
      "La maitrise des risques financiers, opérationnels et technologiques permet aux organisations de garantir une conservation de la valeur créée en protégeant leurs activités. Nous vous aidons à mettre en place et adapter vos dispositifs de gestion des risques pour répondre aux obligations légales et réglementaires, mais également pour garantir un contrôle interne fort. Nous vous fournissons un accompagnement de qualité pour les pré-audits et les missions d’audits défensifs.",
    list: [
      "Audit a blanc",
      "Contrôle interne",
      "Revenue de processus",
      "Fraude et revenue d’assurance",
    ],
  },
];

export const SECTIONS = {
  eveconsulting: "eveconsulting",
  aboutus: "aboutus",
  offers: "offers",
  teameve: "teameve",
  contacts: "contacts",
};

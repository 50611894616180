import React, { useState } from "react";

import { SECTIONS } from "../../../config/constants";

import BtnLink from "../../UICs/BtnLink/BtnLink.uic";
import FormInput from "../../UICs/FormInput/FormInput.uic";
import FormTextarea from "../../UICs/FormTextarea/FormTextarea.uic";

import "./Contacts.styles.scss";

const Contacts = () => {
  const [state, setState] = useState({
    lastname: "",
    firstname: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Submit form!!");
  };

  return (
    <div className="l-contacts" id={SECTIONS.contacts}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img
              src={require("../../../assets/svgs/lighthouse.svg").default}
              className="img-fluid lighthouse"
              alt="lighthouse"
            />
          </div>
          <div className="col-md-6 ">
            <h2 className="contacts-title">Contactez-nous</h2>
            <p className="contacts-text">
              Vous avez une demande ou des commentaires pour nous ? Remplissez
              le formulaire ci-dessous pour contacter notre équipe
            </p>
            <form
              method="post"
              className="contact-form"
              onSubmit={handleSubmit}
            >
              <div className="form-row">
                <div className="form-group col-md-6">
                  <FormInput
                    label="Nom"
                    className="form-control"
                    required
                    value={state.lastname}
                    name="lastname"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <FormInput
                    label="Prénom"
                    className="form-control"
                    value={state.firstname}
                    name="firstname"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <FormInput
                    label="Email"
                    type="email"
                    className="form-control"
                    required
                    value={state.email}
                    name="email"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <FormTextarea
                    label="Message"
                    className="form-control"
                    required
                    value={state.message}
                    name="message"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <BtnLink type="button" inverted isUppercase radius={5}>
                Envoyer
              </BtnLink>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

import React from "react";

import { SECTIONS } from "../../../config/constants";

import "./AboutUs.styles.scss";

const AboutUs = () => {
  return (
    <div className="l-aboutus" id={SECTIONS.aboutus}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="aboutus-title">A propos de nous</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <h3 className="aboutus-subtitle">Nos valeurs</h3>
            <p className="aboutus-text">
              Nos valeurs sont construites au sein des équipes, au fil du temps.
              Nous les transmettons, en interne, dans nos principes de
              management, en externe, dans les relations que nous entretenons
              avec nos partenaires. Elles nous permettent d’être un véritable
              partenaire de confiance pour nos clients.
            </p>
            <div className="aboutus-value">
              <div className="value">
                <div className="value-title">L'écoute du client</div>
                <div className="value-text">
                  Nous sommes à l’écoute de leurs souhaits et besoins et nous
                  leur offrons un service optimal.{" "}
                </div>
              </div>
              <img
                src={require("../../../assets/images/value-1.png").default}
                className="img-fluid"
                alt="value-1"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="aboutus-value circle bg-grey">
              <div className="value">
                <div className="value-title">Le respect</div>
                <div className="value-text">
                  Le respect mutuel et la confiance sont le fondement de toute
                  entreprise prospère
                </div>
              </div>
              <img
                src={require("../../../assets/images/value-3.png").default}
                className="img-fluid"
                alt="value-3"
              />
            </div>
            <br />
            <br />
            <div className="aboutus-value reverse bg-blue">
              <div className="value">
                <div className="value-title">Le dynamisme</div>
                <div className="value-text">
                  Notre capacité d’adaptation face aux changements, aux attentes
                  du marché et aux spécificités de nos clients passe par le
                  dynamisme
                </div>
              </div>
              <img
                src={require("../../../assets/images/value-2.png").default}
                className="img-fluid"
                alt="value-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import React from "react";

import { ButtonContainer, LinkContainer } from "./BtnLink.styles";

const BtnLink = ({
  isUppercase = false,
  radius = 0,
  height = 44,
  children,
  to = "#",
  inverted = false,
  type = "link",
  ...props
}) => {
  return type === "link" ? (
    <LinkContainer
      to={to}
      uppercase={isUppercase ? 1 : 0}
      radius={radius}
      height={height}
      inverted={inverted ? 1 : 0}
      {...props}
    >
      {children}
    </LinkContainer>
  ) : type === "button" ? (
    <ButtonContainer
      to={to}
      uppercase={isUppercase ? 1 : 0}
      radius={radius}
      height={height}
      inverted={inverted ? 1 : 0}
      {...props}
    >
      {children}
    </ButtonContainer>
  ) : (
    <span>Type de bouton non supporté</span>
  );
};

export default BtnLink;

import React from "react";

import { ListTeam, SECTIONS } from "../../../config/constants";

import TeamCard from "../../UICs/TeamCard/TeamCard.uic";

import "./TeamEve.styles.scss";

const TeamEve = () => {
  return (
    <div className="l-teamEve" id={SECTIONS.teameve}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="teamEve-title">L'équipe Everest</h2>

            <div className="teamEve-list">
              {ListTeam.map((team, idx) => (
                <TeamCard key={idx} {...team} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamEve;

// Style Effect of animation
const easeInCubic = function (t) {
  return t * t * t;
};

// Function to scroll to the element
const scrollToDomElement = (
  startTime,
  currentTime,
  duration,
  scrollEndElemTop,
  startScrollOffset
) => {
  const runtime = currentTime - startTime;
  let progress = runtime / duration;

  progress = Math.min(progress, 1);

  const ease = easeInCubic(progress);

  window.scroll(0, startScrollOffset + scrollEndElemTop * ease);

  if (runtime < duration) {
    requestAnimationFrame((timestamp) => {
      const currentTime = timestamp || new Date().getTime();

      scrollToDomElement(
        startTime,
        currentTime,
        duration,
        scrollEndElemTop,
        startScrollOffset
      );
    });
  } else {
    console.log("console2");
  }
};

export default scrollToDomElement;

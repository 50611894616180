import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const btnStyles = css`
  ${(props) => props.uppercase && `text-transform: uppercase;`}
  ${(props) => props.radius !== 0 && `border-radius: ${props.radius}px;`}
  ${(props) => props.height !== 0 && `height: ${props.height}px;`}
background-color: ${(props) => (!props.inverted ? "var(--eve-blue)" : "#fff")};
  color: ${(props) =>
    !props.inverted ? "#fff" : "var(--eve-darkblue)"} !important;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.055em;
  padding: 0 35px;
  text-decoration: none !important;
  transition: border 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--eve-blue);
    color: var(--eve-blue) !important;
    background-color: #fff;
  }
`;

export const LinkContainer = styled(Link)`
  ${btnStyles}
`;

export const ButtonContainer = styled.button`
  ${btnStyles}
`;

import React from "react";

import "./Vision.styles.scss";

const Vision = () => {
  return (
    <div className="l-vision">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="vision">
              <h2 className="vision-title">Notre vision</h2>
              <p className="vision-text">
                Nous croyons beaucoup en l’humain et mettons notre expertise et
                notre savoir-faire au service des entreprises pour
                progresser ensemble. Pour nous, Construire votre présence
                digitale et réaliser des solutions uniques et impactantes,
                permettant de piloter vos résultats en toute autonomie, reste
                notre vision.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="vision-img"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;

import { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import routes from "./config/routes";

import HomePage from "./pages/home/home.page";

import Header from "./components/Layouts/Header/Header.layout";

import scrollToElem from "./helpers/scrollToDomElement";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

import GlobalStyle from "./theme/GlobalStyle.styles";

const App = () => {
  useEffect(() => {
    const links = document.querySelectorAll(".scroll-link");

    Array.from(links).forEach((elem) => {
      elem.addEventListener("click", function (e) {
        e.preventDefault();

        // 1. Get the element id to which you want to scroll
        const scrollElemId = e.target.href.split("#")[1];

        // 2. find that node from the document
        const scrollEndElem = document.getElementById(scrollElemId);

        if (scrollEndElem) {
          const delta = scrollEndElem.dataset.scrolltoDelta
            ? parseInt(scrollEndElem.dataset.scrolltoDelta, 10)
            : 0;

          // 3. and well animate to that node..
          requestAnimationFrame((timestamp) => {
            const stamp = timestamp || new Date().getTime();
            const duration = 1000;
            const start = stamp;

            setTimeout(() => {
              activeLink(
                links,
                document.querySelector(`[data-link="${scrollElemId}"]`)
              );
            }, duration);

            const startScrollOffset = window.pageYOffset;
            const scrollEndElemTop =
              scrollEndElem.getBoundingClientRect().top - delta;

            scrollToElem(
              start,
              stamp,
              duration,
              scrollEndElemTop,
              startScrollOffset
            );
          });
        }
      });
    });
  }, []);

  const activeLink = (links, target) => {
    Array.from(links).forEach((link) => link.classList.remove("active"));

    if (target) target.classList.add("active");
  };

  return (
    <>
      <GlobalStyle />
      <Header />
      <Switch>
        <Route exact component={HomePage} path={routes.root} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 100000 }}
      />
    </>
  );
};

export default App;

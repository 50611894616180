import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";

import { SECTIONS, SliderData } from "../../../config/constants";

import BtnLink from "../../UICs/BtnLink/BtnLink.uic";

import "./Offers.layout.scss";

const Offers = () => {
  const interval = useRef(null);

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    startSlider();

    return () => {
      stopInterval(interval.current);
    };
  }, []);

  const length = SliderData.length;

  const nextSlide = () => {
    setCurrent((current) => (current === length - 1 ? 0 : current + 1));
  };

  // const prevSlide = () => {
  //   setCurrent((current) => (current === 0 ? length - 1 : current - 1));
  // };

  const selectSlide = (idx) => {
    setCurrent(idx);
  };

  const startSlider = (time = 4000) => {
    clearInterval(interval.current);

    interval.current = setInterval(() => {
      nextSlide();
    }, time);
  };

  const onMouseEnter = (e) => {
    e.stopPropagation();
    stopInterval();
  };

  const onMouseLeave = (e) => {
    e.stopPropagation();
    startSlider();
  };

  const stopInterval = () => {
    clearInterval(interval.current);
  };

  return (
    <div className="l-offers" id={SECTIONS.offers}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="offers">
              <h2 className="offers-title">Ce que nous offrons</h2>
              <p className="offers-text">
                Nous assistons nos clients dans l’identification de nouveaux
                relais de croissance et dans leurs mutations digitales par:
              </p>
            </div>

            <div
              className="slider"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <div className="slider-items">
                {SliderData.map((slide, idx) => (
                  <div
                    key={idx}
                    className={
                      idx === current ? "slider-item active" : "slider-item"
                    }
                    onClick={() => selectSlide(idx)}
                  >
                    <Icon icon={slide.icon} style={{ fontSize: "83px" }} />
                    <span className="slider-text">{slide.title}</span>
                  </div>
                ))}
              </div>
              <div className="content-slider">
                <div className="content-body">
                  <h6 className="content-slider-title">
                    {SliderData[current].title}
                  </h6>
                  <p className="content-slider-text">
                    {SliderData[current].description}
                  </p>
                  <ul className="content-slider-list">
                    {SliderData[current].list.map((li, idx) => (
                      <li key={`list-${idx}`}>{li}</li>
                    ))}
                  </ul>
                </div>
                <div className="slideshow-nav">
                  {SliderData.map((dot, idx) => (
                    <span
                      key={`dot-${idx}`}
                      className={
                        idx === current ? "slider-dot active" : "slider-dot"
                      }
                      onClick={() => selectSlide(idx)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <br />
            <div className="d-flex justify-content-center mt-5">
              <BtnLink
                isUppercase
                className="scroll-link"
                radius={34}
                height={58}
                to={`#${SECTIONS.contacts}`}
              >
                Nous contacter
              </BtnLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;

import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { SECTIONS } from "../../../config/constants";

import routes from "../../../config/routes";

import "./Navbar.styles.scss";

const Navbar = ({ didScroll }) => {
  return (
    <nav
      role="navigation"
      className={classnames("l-navbar navbar navbar-expand-lg navbar-light", {
        didScroll,
      })}
    >
      <Link className="navbar-brand" to={`${routes.root}`}>
        {!didScroll ? (
          <img
            className="img-fluid"
            src={require("../../../assets/images/logo.png").default}
            alt="logo"
          />
        ) : (
          <img
            className="img-fluid"
            src={require("../../../assets/images/logo-min.png").default}
            alt="logo-min"
          />
        )}
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse navbar-nav-scroll"
        id="navbarNavAltMarkup"
      >
        <div className="navbar-nav align-items-end ml-auto">
          <a
            className="nav-link scroll-link"
            data-link={`${SECTIONS.eveconsulting}`}
            href={`#${SECTIONS.eveconsulting}`}
          >
            Everest consulting
          </a>
          <a
            className="nav-link scroll-link"
            data-link={`${SECTIONS.aboutus}`}
            href={`#${SECTIONS.aboutus}`}
          >
            A propos de nous
          </a>
          <a
            className="nav-link scroll-link"
            data-link={`${SECTIONS.offers}`}
            href={`#${SECTIONS.offers}`}
          >
            Offres
          </a>
          <a
            className="nav-link scroll-link"
            data-link={`${SECTIONS.teameve}`}
            href={`#${SECTIONS.teameve}`}
          >
            L'équipe
          </a>
          <a
            className="nav-link scroll-link custom-nav-link"
            href={`#${SECTIONS.contacts}`}
          >
            Contactez-nous
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

    :root {
        --eve-darkblue: #084A8B;
        --eve-blue: #1776BB;
        --eve-clearblue: #24AAE2;
        --eve-nav-link: #878787;
        --eve-grey: #474A55
    }

    * {
        box-sizing: border-box;
    }

    body, html {
        padding: 0;
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        overflow-x: hidden;
    }
`;

export default GlobalStyle;

import AboutUs from "../../components/Layouts/AboutUs/AboutUs.layout";
import Contacts from "../../components/Layouts/Contacts/Contacts.layout";
import EveConsulting from "../../components/Layouts/EveConsulting/EveConsulting.layout";
import Footer from "../../components/Layouts/Footer/Footer.layout";
import Missions from "../../components/Layouts/Missions/Missions.layout";
import Offers from "../../components/Layouts/Offers/Offers.layout";
import TeamEve from "../../components/Layouts/TeamEve/TeamEve.layout";
import Vision from "../../components/Layouts/Vision/Vision.layout";

import "./home.styles.scss";

const HomePage = () => {
  return (
    <div className="home-page">
      <EveConsulting />
      <AboutUs />
      <Missions />
      <Vision />
      <Offers />
      <TeamEve />
      <Contacts />
      <Footer />
    </div>
  );
};

export default HomePage;

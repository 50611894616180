import React from "react";
import { Icon } from "@iconify/react";

import bxsQuoteLeft from "@iconify/icons-bx/bxs-quote-left";
import bxsQuoteRight from "@iconify/icons-bx/bxs-quote-right";

import {
  CardBody,
  CardContainer,
  CardHead,
  CardHeader,
  CardImg,
  CardText,
  Quote,
} from "./TeamCard.styles";

const TeamCard = ({ img, name, role, description }) => {
  return (
    <CardContainer>
      <CardHead>
        <CardImg img={img} />

        <CardHeader>
          <h6>{name}</h6>
          <p>{role}</p>
        </CardHeader>
      </CardHead>
      <CardBody>
        <Quote>
          <Icon icon={bxsQuoteLeft} style={{ fontSize: "35px" }} />
        </Quote>
        <CardText>{description}</CardText>
        <Quote bottom>
          <Icon icon={bxsQuoteRight} style={{ fontSize: "35px" }} />
        </Quote>
      </CardBody>
    </CardContainer>
  );
};

export default TeamCard;

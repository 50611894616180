import React from "react";

import { SECTIONS } from "../../../config/constants";

import "./Footer.styles.scss";

const Footer = () => {
  return (
    <div className="l-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footer">
              <div className="brand-footer">
                <span className="top">Everest</span>
                <span className="bottom">Consulting</span>
              </div>
              <nav className="nav footer-nav">
                <a
                  className="nav-link scroll-link"
                  href={`#${SECTIONS.eveconsulting}`}
                >
                  Everest consulting
                </a>
                <a
                  className="nav-link scroll-link"
                  href={`#${SECTIONS.aboutus}`}
                >
                  A propos de nous
                </a>
                <a
                  className="nav-link scroll-link"
                  href={`#${SECTIONS.offers}`}
                >
                  Offres
                </a>
                <a
                  className="nav-link scroll-link"
                  href={`#${SECTIONS.teameve}`}
                >
                  L'équipe
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

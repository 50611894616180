import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import Navbar from "../Navbar/Navbar.layout";

import "./Header.styles.scss";

const Header = () => {
  const sticking = useRef(false);
  const lastScrollPosition = useRef(0);

  const [didScroll, _setDidScroll] = useState(false);

  const didScrollRef = useRef(didScroll);

  const setDidScroll = (data) => {
    didScrollRef.current = data;
    _setDidScroll(data);
  };

  useEffect(() => {
    stickingHeader();
    window.addEventListener("scroll", stickingHeader);

    return () => {
      window.removeEventListener("scroll", stickingHeader);
    };
  }, []);

  const showNavBar = (scrollPosition) => {
    if (scrollPosition > 124 && !didScrollRef.current) {
      setDidScroll(true);
    } else if (scrollPosition <= 0 && didScrollRef.current) {
      setDidScroll(false);
    }
  };

  const stickingHeader = () => {
    lastScrollPosition.current = window.scrollY;

    if (!sticking.current) {
      window.requestAnimationFrame(function () {
        showNavBar(lastScrollPosition.current);
        sticking.current = false;
      });
    }

    sticking.current = true;
  };

  return (
    <>
      <div
        className={classnames("l-header", {
          "fixed-top": didScroll,
          "shadow-sm": didScroll,
        })}
      >
        <div className="container">
          <Navbar didScroll={didScroll} />
        </div>
      </div>
      {didScroll && <div style={{ height: "204px" }}></div>}
    </>
  );
};

export default Header;
